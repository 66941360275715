import gsap from 'gsap';
import {
  Component, EventEmitter, Input,
  Output,
} from '@angular/core';

// #region Interfaces
export interface UserDataCell {
  avatar?: Component;
  fullName: string;
  email?: string;
  icon?: string;
}

export interface GeneralDataCell {
  icon?: string;
  mainText: string | number;
  subText?: string | number;
  badgeCount?: number;
}

export interface StatusDataCell {
  statusDisplay: string;
  statusKid: string;
  statusType: string;
}

export interface SpecialDataCell {
  specialType: 'checkbox' | 'icon' | 'number' | 'validation',
  icon?: string;
  actionName: string;
}

interface ColumnHeader {
  name: string;
  icon?: string;
  sortable?: boolean;
  sortByProp?: string; // should be the data property name from the DB
  isDefaultSort?: boolean;
  sortDirection?: 'ASC' | 'DESC' | null;
  width?: string;
  align?: 'left' | 'right' | 'center';
}

export interface NewTableData {
  title: string;
  rowStyling: 'default' | 'dense';
  noDataFoundMessage: string;

  columnTitles: ColumnHeader[];

  data: {
    itemId: number;
    columnData: (UserDataCell | GeneralDataCell | StatusDataCell | SpecialDataCell)[];
  }[] | [];


  hasBurgerMenu?: boolean;
  hamburgerMenuWidth?: string;
  burgerContent?: {
    content: string;
    eventName: string;
  }[];

  meta?: {
    totalItems: number;
    itemsPerPage: number;
    curPage: number;
    totalPages: number;

    usePagination: boolean;
    links: {
      prev: string;
      next: string;
      first: string;
      last: string;
    }
  }
}
// #endregion Interfaces

@Component({
  selector: 'app-new-table',
  templateUrl: './new-table.component.html',
  styleUrls: [ './new-table.component.scss' ],
})
export class NewTableComponent {
  @Input() tableData: NewTableData;
  @Input() isLoading = false;
  @Output() burgerMenuClickedEvent = new EventEmitter<{ eventName: string; itemId: number; }>();
  @Output() sortClickedEvent = new EventEmitter<{ columnName: string; sortDirection: 'ASC' | 'DESC' }>();
  @Output() specialActionClickedEvent = new EventEmitter<{ actionName: string; itemId: number; value?: unknown }>();
  @Output() rowClickedEvent = new EventEmitter<number>();

  initialSortedColumn: ColumnHeader | null;

  ngOnInit() {
    const hasSort = this.tableData.columnTitles.some(col => col.sortable);

    if (!hasSort) {
      return;
    }

    const initialSorted = this.tableData.columnTitles.find(col => col.isDefaultSort) as ColumnHeader;

    if (initialSorted) {
      this.initialSortedColumn = initialSorted;
    }
  }

  // #region Helper Functions
  cellIsUserInfo(cell: unknown): cell is UserDataCell {
    return !!(cell as UserDataCell).fullName;
  }

  cellIsGeneralInfo(cell: unknown): cell is GeneralDataCell {
    return !!(cell as GeneralDataCell).mainText;
  }

  cellIsStatusInfo(cell: unknown): cell is StatusDataCell {
    return !!(cell as StatusDataCell).statusDisplay;
  }

  cellIsSpecialInfo(cell: unknown): cell is SpecialDataCell {
    return !!(cell as SpecialDataCell).specialType;
  }

  // #endregion

  // #region Output executors
  rowClicked(itemId: number) {
    this.rowClickedEvent.emit(itemId);
  }
  burgerMenuClicked(eventName: string, itemId: number) {
    this.burgerMenuClickedEvent.emit({ eventName, itemId });
  }

  sortClicked(column: ColumnHeader) {
    this.tableData.columnTitles.forEach(col => {
      if (!col.sortable) {
        return;
      }

      if (col.sortDirection === undefined) {
        col.sortDirection = null;
      }

      if (col.name !== column.name) {
        col.sortDirection = null;
      }
    });


    const curSortDir = column.sortDirection;

    if (curSortDir === null) {
      column.sortDirection = 'ASC';
    } else if (curSortDir === 'ASC') {
      column.sortDirection = 'DESC';
    } else if (curSortDir === 'DESC' && this.initialSortedColumn) {
      column.sortDirection = null;
      this.initialSortedColumn.sortDirection = 'ASC';
    }

    const sortedColumn = this.tableData.columnTitles.find(col => col.sortDirection !== null) as ColumnHeader;
    this.sortClickedEvent.emit({
      columnName: sortedColumn.sortByProp as string,
      sortDirection: sortedColumn.sortDirection as 'ASC' | 'DESC',
    });

    const table = document.querySelector('.table') as HTMLElement;
    // scroll table to top
    table.scrollIntoView();
  }

  specialActionClicked(
    actionName: string, itemId: number, value?: string,
  ) {
    this.specialActionClickedEvent.emit({
      actionName, itemId, value,
    });
  }

  // #endregion

}
