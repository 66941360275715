export const environment = {
  expressUrl: 'https://stg-api.kalpapdmsdev.com',
  production: false,
  amplify: {
    Auth: {
      region: 'us-west-2',
      userPoolId: 'us-west-2_5g6RZrS99',
      userPoolWebClientId: '3ls1bavall6cplgcivqkn3s09d',
      identityPoolId: 'us-west-2:befc54d6-9792-486a-a7d2-f66eca8ff658',
    },
  },
};
