<div class="modal">
  <div class="title-row">
    <div class="title">
      <mat-icon fontSet="material-icons-round">check_circle</mat-icon>
      <span class="kbody-huge">Update Course Status</span>
    </div>
    <mat-icon
      (click)="closeStatusModal()"
      fontSet="material-icons-round"
      class="close"
      >close</mat-icon
    >
  </div>
  <div class="content">
    <!-- TODO - ASAP - alter ngIF for both taken and not taken to check validation type and course date -->
    <div
      *ngIf="
        data.currentStatusKid !== 'WAITLISTED' &&
        data.currentStatusKid !== 'PENDING'
      "
      class="btn-row"
    >
      <input
        type="radio"
        id="taken"
        name="status"
        value="taken"
        [(ngModel)]="status"
      />
      <span class="kbody-large">I have taken this course</span>
    </div>
    <div
      *ngIf="
        data.currentStatusKid !== 'WAITLISTED' &&
        data.currentStatusKid !== 'PENDING'
      "
      class="btn-row"
    >
      <input
        type="radio"
        id="not-taken"
        name="status"
        value="not-taken"
        [(ngModel)]="status"
      />
      <span class="kbody-large">I did not take this course</span>
    </div>
    <div class="btn-row">
      <input
        type="radio"
        id="cancel"
        name="status"
        value="cancel"
        [(ngModel)]="status"
      />
      <span class="kbody-large">Cancel my registration</span>
    </div>
  </div>
  <div class="buttons">
    <app-button
      class="kbutton"
      (click)="closeStatusModal()"
      class="white"
      text="Cancel"
    ></app-button>
    <app-button
      class="kbutton"
      (click)="submitStatus()"
      text="Update Status"
    ></app-button>
  </div>
</div>
